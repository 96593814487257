import styled from 'styled-components'
import CubeIFrame from './CubeIFrame';
import MediaAndDescription from './MediaAndDescription';

const Background = styled.div`
@media not screen and (max-width: 1099px) {
    background: url(resources/background.png) no-repeat center top;
    background-size: cover;
  }

  @media screen and (max-width: 1099px) {
    background-color: #2f2f2f;
  }
`;

const Fade = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
`;

const CentreContent = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: auto;
`;

const MenuItems = styled(CentreContent)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const MenuBar = styled.div`
  width: 100%;
  background-color: #2f2e2e;
`;

const MenuItem = styled.a`
  color: white;
  font-size: 26px;
  padding: 3px 10px 3px 10px;

  &:hover {
    color: grey;
  }
`;

const Title = styled.h1`
  color: white;
  text-align: center;
`;

const MainTitle = styled(Title)`
  font-size: 48px;
  margin-bottom: 0px;
`;

const Subtitle = styled(Title)`
  font-size: 28px;
  margin-top: 5px;
`;

const Bubble = styled.div`
  background-color: #2b2b2b;
  border-radius: 8px;
  box-shadow: 1px 1px 3px 1px #000000;
  color: white;
  padding: 15px;
`;

const TitleBubble = styled(Bubble)`
  font-size: 28px;
  font-weight: bold;
  width: fit-content;
  margin-top: 20px;
`;

const ContentBubble = styled(Bubble)`
  width: 100%;
  margin-top: 10px;
  font-size: 20px;
  box-sizing: border-box;

  a {
    color: white;
  }
`;

const Image = styled.img`
  max-width: 100%;
  border-radius: 5px;
`;

const MainImage = styled.img`
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
`;

const IFrame = styled.iframe`
  width: 100%;
  height: 270px;
  border: none;
  allowFullScreen;
  margin-left: auto;
  margin-right: auto;
`;

function App() {
  return <Background>
    <Fade>
      <MenuBar>
        <MenuItems>
          <MenuItem href="#About">About</MenuItem>
          <MenuItem href="#Projects">Projects</MenuItem>
          <MenuItem href="#Contact">Contact</MenuItem>
        </MenuItems>
      </MenuBar>
      <CentreContent>
        <MainTitle>Tori Hamilton</MainTitle>
        <Subtitle>Programmer</Subtitle>
        <MainImage src="/resources/me.png"/>
        <TitleBubble id="About">About Me</TitleBubble>
        <ContentBubble>I am a programmer from the Sydney area who loves clean code and complex problems. This website outlines the various projects I have worked on throughout my years of experience in programming.</ContentBubble>
        <TitleBubble id="Projects">My Projects</TitleBubble>
        <ContentBubble>
          <MediaAndDescription title="This Website" description={<>I created this website using React. Hosted on Google Firebase.</>}/>
        </ContentBubble>
        <ContentBubble>
          <MediaAndDescription mediaSide="right" title="Prospa" description={<> For the past year I have worked at <a href="https://www.prospa.com/">Prospa</a> on
                                                                                our martech stack. This work enables the Prospa marketing team operate efficiently as possible with minimal
                                                                                engineering effort. This work heavily utilised resilient event-driven architecture, as well as integrations
                                                                                with the core components of our martech stack, including our Customer Data Platform, our Marketing Platform,
                                                                                and our Identity Resolution Platform.
                                                                                <br/><br/>
                                                                                Some of the technologies involved in this work include ASP.NET, Neo4J, React, Segment, and Braze, built on Azure.</>}>
            <Image src="resources/prospa.png"/>
          </MediaAndDescription>
        </ContentBubble>
        <ContentBubble>
          <MediaAndDescription mediaSide="left" title="Tes Global" description={<>  I worked for four years at <a href="https://www.tes.com/en-au/for-schools/timetabling-edval">Tes Global</a> on
                                                                                    a timetabling product called Edval. My work on Edval included various aspects of programming such as 
                                                                                    algorithm development, UI work, legacy code translation, and technical debt refactoring.
                                                                                    A large part of my role at Tes was also in integrations management; Engaging in communications, development,
                                                                                    testing, and documenting integrations with third parties to seamlessly connect with their software. My role at Tes
                                                                                    also allowed me to provide technical leadership, mentoring, and management to a team of developers
                                                                                    in a project to move the legacy Edval timetabling products to a modern cloud-based SaaS environment using
                                                                                    ASP.NET, Entity Framework, and React, built on AWS.</>}>
            <Image src="resources/edval.png"/>
          </MediaAndDescription>
        </ContentBubble>
        <ContentBubble>
          <MediaAndDescription mediaSide="right" title="Search for Search" description={<>Search for Search is a Google Chrome browser extension that I developed as a personal project. Search for Search provides 
                                                                                          a simple keybind (Ctrl+Shift+S) to navigate to the search bar on almost any website. The extension searches the HTML of the 
                                                                                          active webpage for a search type input fields, text type input field which may be search related, or buttons which may reveal search bars.
                                                                                          <br/><br/>
                                                                                          <a href="https://chrome.google.com/webstore/detail/search-for-search/gbccibamlfjjcilphhjcgadleioinljj">Download Search for Search</a> - (Chrome Web Store)
                                                                                          </>}>
            <IFrame src="https://player.vimeo.com/video/325647362"/>
          </MediaAndDescription>
        </ContentBubble>
        <ContentBubble>
          <MediaAndDescription mediaSide="left" title="Deferred Lighting" description={<> This is a simulation I developed as a personal project, written in C++. This simulation demonstrates deferred lighting and normal mapping techniques, 
                                                                                          which are commonly used in games and simulations development. While the entire brick wall is a flat surface, the left side of the wall uses normal mapping 
                                                                                          to scatter light reflections, giving the illusion of additional detail, while the right does not. The simulation uses the OpenGL and GLEW libraries for 
                                                                                          rendering, GLFW for window/input handling, and Freetype to parse font files.<br/><br/>Controls: W,A,S,D,Ctrl,Space to move. Right-Click + Mouse for Camera. 
                                                                                          Click on UI buttons to adjust light count.<br/><br/>
                                                                                          <a href="downloads/Deferred Lighting.zip" download>Download Simulation</a> - (.zip) Currently Windows Only.</>}>
            <IFrame src="https://player.vimeo.com/video/350589802"/>
          </MediaAndDescription>
        </ContentBubble>
        <ContentBubble>
          <MediaAndDescription mediaSide="right" title="Desert Storm" description={<> Desert Storm is a grapics simulation I developed for university assessment. Desert Storm was coded in c++ using the OpenGL and GLFW libraries. The main features of 
                                                                                      the desert storm simulation are the GPU-based particle tornado and the post-processing kernel effects<br/><br/>Controls: W,A,S,D,Q,E to move. Right-Click + Mouse for 
                                                                                      Camera. Double-Click on UI tabs to modify variables - Try the tornado!<br/><br/>
                                                                                      <a href="downloads/Desert Storm.zip" download>Download Desert Storm</a><span> - (.zip) Currently Windows Only.</span></>}>
            <IFrame src="https://player.vimeo.com/video/190068454"/>
          </MediaAndDescription>
        </ContentBubble>
        <ContentBubble>
          <MediaAndDescription mediaSide="left" title="Cubic Voxels Plus" description={<> Cubic Voxels Plus is a voxel framework I developed in C# for the Unity game engine. This product was created for my final project at university.
                                                                                          The product was created as a foundation for other developers to build voxel based games within the Unity game engine easily.<br/><br/>There are many
                                                                                          technical features of Cubic Voxels Plus. Firstly, the meshes of adjacent voxels are combined and optimized to reduce storage space and rendering times.
                                                                                          Additionally, the entire voxel world is serialized to disc to preserve the world between game sessions. This is done in such a way that the speed of file
                                                                                          reads and writes to not increase drastically with larger world sizes. Both mesh optimisation and file I/O is processed on worker threads to reduce the load
                                                                                          on the main thread.<br/><br/>
                                                                                          <a href="downloads/Cubic Voxels Plus.zip" download>Download Cubic Voxels Plus</a> - (.zip) Product for Unity, Demo Game built for Windows.</>}>
            <IFrame src="https://www.youtube.com/embed/yU7kNDgA23A"/>
            <IFrame src="https://www.youtube.com/embed/4jWOvkAphaU"/>
          </MediaAndDescription>
        </ContentBubble>
        <ContentBubble>
          <MediaAndDescription title="Interactive Puzzle Cube" description={<><CubeIFrame src="cube"></CubeIFrame>
                                                                              I developed this interactive puzzle cube to broaden my experience from OpenGL to include WebGL / OpenGL ES. The cube was developed in Typescript, with only a single library 
                                                                              used for matrix math implementations.<br/><br/>Click and drag on the cube pieces to move them. Click and drag outside of the cube (but within the border) to rotate.<br/><br/>
                                                                              If the puzzle cube has not loaded, your device may not support WebGL. I've found that up to date versions of Google Chrome and Safari work well.</>}/>
        </ContentBubble>
        <TitleBubble id="Contact">Contact Me</TitleBubble>
        <ContentBubble>For business opportunities please contact me via e-mail: <a href="mailto:torihamilton20@gmail.com">torihamilton20@gmail.com</a></ContentBubble>
        <br/>
      </CentreContent>
    </Fade>
  </Background>;
}

export default App;
